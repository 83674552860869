import React from 'react';

class SsoLinks extends React.Component {
  render() {
    return (
      <div className="login-newui__link-container">
        {this.props.login && (
          <p className="sep">
            <a className="login-newui__link" href={`/signup/${this.props.sso_token}`}>
              {I18n.t('signup.question')}
            </a>
          </p>
        )}

        {this.props.login && (
          <p className="sep">
            <a className="login-newui__link" target="_blank" href="/forgot">
              {I18n.t('forgot.question')}
            </a>
          </p>
        )}

        {this.props.signup && (
          <p className="sep">
            <a className="login-newui__link" href={`/login/${this.props.sso_token}`}>
              {I18n.t('login.question')}
            </a>
          </p>
        )}
      </div>
    );
  }
}

export default SsoLinks;
