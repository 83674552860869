import React from 'react';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';
import {client} from '../thewatch/fetch/client.js';

export default class Configured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
      loading: true,
      email: null
    };
  }

  componentDidMount() {
    client('GET', '/auth/configuration/configured.json')
      .then((response) => {
        const data = response.data;
        return this.setState({
          device: data.device,
          email: data.email,
          loading: false
        });
      })
      .catch((response) => {
        const error = response.error;
        this.setState({
          errors: error.errors,
          state: error.errors,
          flashes: error.flash,
          loading: false
        });
      });
  }

  goToApp = (ev) => {
    ev.preventDefault();
    window.location.href = '/';
  };

  render() {
    return (
      !this.state.loading && (
        <div className="session-container">
          <div className="configured--logo">
            <div className="top-icon">
              <figure>
                <NewIcoPrey />
              </figure>
            </div>
          </div>
          <div className="configured--content">
            <div className="configured--content__title">
              {I18n.t('node_config.configured.title', {email: this.state.email})}
            </div>
            <div className="configured--content__description">
              {I18n.t('node_config.configured.desc_1')}
              <b>{I18n.t('node_config.configured.desc_2')}</b>
              {I18n.t('node_config.configured.desc_3')}
              <b>{I18n.t('node_config.configured.desc_4')}</b>
              {I18n.t('node_config.configured.desc_5')}
              <b>{I18n.t('node_config.configured.desc_6')}</b>
              {I18n.t('node_config.configured.desc_7')}
            </div>
          </div>
          <div className="configured--button">
            <button className="button button--primary expanded" onClick={this.goToApp}>
              {I18n.t('node_config.configured.button')}
            </button>

            <a
              rel="noopener"
              href="https://support.preyproject.com/hc/en-us/articles/17236962523661-How-to-uninstall-Prey-for-Computers"
            >
              {I18n.t('node_config.configured.need_help')}
            </a>
          </div>
        </div>
      )
    );
  }
}
