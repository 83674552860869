import React from 'react';
import {Link} from 'react-router-dom';
import serialize from 'form-serialize';
import {client} from '../thewatch/fetch/client.js';
import {recaptcha} from '../commons.js';

class Forgot extends React.Component {
  state = {
    flashes: [],
    redirect: false,
    path: '',
    email: '',
    error: null,
    errors: {},
    loading: false
  };

  componentDidMount() {
    recaptcha.mount(this.sendForm);
  }

  componentWillUnmount = () => {
    grecaptcha.reset();
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    grecaptcha.execute();
  };

  sendForm = (recaptchaToken) => {
    Debug(recaptchaToken);

    this.setState({
      loading: true
    });

    const data = serialize(document.getElementById('forgot-form'), {empty: true, hash: false});

    client('POST', '/forgot.json', data, true)
      .then((response) => {
        const data = response.data;
        let obj = {
          path: data.path
        };
        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;
        this.setState(obj, () => {
          if (data.path !== '/forgot') {
            this.props.handleSuccess(data);
          }
        });
      })
      .catch((response) => {
        const error = response.error;
        this.setState({
          errors: error.errors,
          state: error.errors,
          flashes: error.flash,
          loading: false
        });
      });
  };

  updateEmail = (event) => {
    event.preventDefault();

    this.setState({
      email: event.target.value
    });
  };

  emailEntered = () => {
    return this.state.email !== '';
  };

  render() {
    return (
      <div className="sign-form">
        <form id="forgot-form" className="form form-session" onSubmit={this.handleSubmit}>
          <div className="g-recaptcha" id="recaptcha" />

          {this.state.flashes.map((o, i) => {
            return (
              <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
                {o[1]}
              </p>
            );
          })}
          <h1 className="login-newui__h1">{I18n.t('login.forgotten_pass')}</h1>
          <ul className="sign-form">
            <div>
              <li className="sep">
                <p className="login-newui__title">{I18n.t('forgot.title')}</p>
              </li>

              <li className="sep">
                <label className="login-newui__label" htmlFor="email">
                  {I18n.t('forgot.label')}
                </label>
                <input
                  type="email"
                  ref="email_input"
                  name="email"
                  id="email"
                  tabIndex="1"
                  className="login-newui__input"
                  autoFocus="autoFocus"
                  onChange={this.updateEmail}
                />
              </li>

              <li className="sep">
                <input
                  type="submit"
                  name="commit"
                  tabIndex="2"
                  value={this.state.loading ? I18n.t('forgot.submitting') : I18n.t('forgot.submit')}
                  className="login-newui__button"
                  disabled={!this.emailEntered() || this.state.loading}
                  className={this.emailEntered() ? 'login-newui__button' : 'login-newui__button--disabled'}
                  style={this.emailEntered() ? {} : {cursor: 'default'}}
                  style={{marginTop: '16px'}}
                />
              </li>
              <div className="login-newui__link-container">
                <p className="sep">
                  <Link className="login-newui__link" to="/login">
                    {I18n.t('login.link')}
                  </Link>
                </p>

                <p className="sep">
                  <Link className="login-newui__link" to="/signup">
                    {I18n.t('signup.question')}
                  </Link>
                </p>
              </div>
            </div>
          </ul>
        </form>
      </div>
    );
  }
}

export default Forgot;
